import React from "react"
import strechedHeroStyles from "./strechedHero.module.scss"
import imgHero from "../../images/heroStudy.png"

export default function strechedHero(props) {
  return (
    <div className={strechedHeroStyles.container}>
      <div className={strechedHeroStyles.imgContainer}>
        <img src={imgHero} className={strechedHeroStyles.img} alt="heroStudy" />
      </div>
      <div className={strechedHeroStyles.textContainer}>
        <h1 className={strechedHeroStyles.title}>TIPS PARA ESTUDIAR</h1>
        <hr className={strechedHeroStyles.hr}></hr>
        <p className={strechedHeroStyles.text}>
          Ni en el colegio ni en el instituto te enseñan maneras de estudiar
          bien. Al menos, a mí no me enseñaron ningún tipo de técnica ni
          estrategia. La forma tradicional es memorizar repitiendo mentalmente o
          escribiendo, pero eso no funciona, es una pérdida de tiempo y resulta
          aburrido.
        </p>

        <p className={strechedHeroStyles.text}>
          En mi primer año de carrera me cansé de memorizar, así que comencé a
          preocuparme sobre cómo estudiar mejor para los exámenes, de formas más
          efectivas, más divertidas y más rápidas.Sin exagerar, aprender formas
          de estudiar bien y rápido me permitió subir bastante mis
          calificaciones; en muchas asignaturas subí hasta más de dos puntos.
          ¿Puedes hacerlo tú también? Por supuesto, con solo aprender ciertas
          técnicas sencillas y hábitos podrás mejorar mucho tus
          calificaciones.Dudo que hubiese sido capaz de sacar tan buenas
          calificaciones sin ninguna técnica de memorización o de estudio, ya
          que la Universidad es dura y tienes que aprender muchos datos y
          conocimientos.
        </p>

        <p className={strechedHeroStyles.text}>
          {" "}
          Los consejos que te contaré te servirán para exámenes de admisión, de
          historia, de inglés, oposiciones, lengua, de primaria, finales,
          convocatoria o prueba, química, la tabla periódica… En definitiva,
          cualquier asignatura o conocimientos que tengas que aprender.
        </p>

        <p className={strechedHeroStyles.text}>
          <strong> Toma buenas notas.</strong>
        </p>

        <p className={strechedHeroStyles.text}>
          Si tienes poco tiempo para estudiar para un examen, hacer unas buenas
          anotaciones y resúmenes es vital para aprovechar bien la noche.
        </p>

        <p className={strechedHeroStyles.text}>
          {" "}
          <strong>Escoge lo que de verdad necesitas estudiar.</strong> Si tu
          profesor realiza sesiones de repaso antes del examen, aprovéchala para
          saber qué temas considera más importantes. Además, puedes plantear
          preguntas (aunque si no has empezado a estudiar, probablemente tengas
          demasiadas preguntas). Muchos profesores realizan esquemas para
          estudiar lo más importante. Aprovéchalos bien. Aunque no contengan
          toda la información que se abarca en el examen, al menos podrás
          concentrarte en los puntos principales.
        </p>

        <p className={strechedHeroStyles.text}>
          {" "}
          <strong>Usa tus notas de clase. </strong>, Si has asistido a clases
          regularmente, deberías tener notas para repasar. Si no tienes ninguna,
          intenta conseguir las de algún compañero. Las notas que se hacen en
          clase son un tesoro, porque contienen lo que el profesor considera más
          importante de cada tema.{" "}
        </p>
      </div>
    </div>
  )
}
